<!--
  服务的详情
    + 可以审核服务
        当其状态为待审核时
    + 查看服务详情
 -->
<template>
  <div class="detail">
    <detail-header title="服务详情" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <!-- 自定义表单 -->
        <custom-form
          ref="customForm"
          mode="static"
          :formModel="fieldList"
          :formData="formData">
          <custom-form-item
            v-for="item in fieldList"
            :key="item.column"
            :item="item"
            :value.sync="formData[item.column]">
          </custom-form-item>
        </custom-form>

        <div class="add_btn_box tw-mt-40px" v-if="formData.logicStatus === globalVar.SERVICE_STATUS_0">
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="onAudit(globalVar.SERVICE_STATUS_1)">通过
          </el-button>
          <el-button
            type="danger"
            size="small"
            :loading="btnLoading"
            plain
            @click="onAudit(globalVar.SERVICE_STATUS_2)">驳回
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
export default {
  data () {
    return {
      // 页面loading
      loading: false,
      btnLoading: false,
      // 字段数组
      fieldList: [],
      // 表单数据
      formData: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  components: {
    customForm,
    customFormItem
  },
  created () {
    this.getServiceDetail()
  },
  methods: {
    getServiceDetail () {
      this.loading = true
      api.getServiceDetail(this.id).then(res => {
        if (res.data.code === 0) {
          console.log('服务详情', res.data.data)
          this.fieldList = res.data.data.fields
          this.formData = res.data.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 通过或拒绝
    onAudit (logicStatus) {
      // 如果是已拒绝 则需要填写拒绝理由
      if (logicStatus === globalVar.SERVICE_STATUS_2) {
        this.$prompt('请输入驳回原因', '提示', {
          confirmButtonText: '驳回',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入驳回原因',
          inputPlaceholder: '请输入驳回原因'
        }).then(({ value }) => {
          this.onStatus('', '', logicStatus, value)
        }).catch(() => {
        })
      } else {
        this.$msgbox({
          title: '确认',
          message: '请确认你的操作',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.onStatus(instance, done, logicStatus)
            } else {
              done()
            }
          }
        })
      }
    },
    onStatus (instance, done, logicStatus, reason) {
      if (instance) {
        instance.confirmButtonLoading = true
      }
      this.btnLoading = true
      api.editServiceStatus(this.id, {
        logicStatus, // 被更新的状态
        reason // 驳回原因
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getServiceDetail()
        } else {
          this.$message.error(res.data.message)
        }
        done && done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
        if (instance) {
          instance.confirmButtonLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;

      .add_btn_box {
        padding-left: 180px;
      }
    }
  }
}
</style>
